import React, {useEffect, useState} from 'react'
import Banner from '../components/Banner'
import Events from '../components/Events'
import Footer from '../components/Footer'
import GetInvoled from '../components/GetInvoled'
import Reports from '../components/Reports'
import "../styles/Home.css"
import NewsFeeds from '../components/NewsFeeds/NewsFeeds'



const Home = () => {
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    function backTop() {
      if (window.scrollY <= 480) {
        setBackToTop(false);
      }
      if (window.scrollY >= 480) {
        setBackToTop(true);
      }
    }

    window.addEventListener("scroll", backTop);
    return () => window.addEventListener("scroll", backTop);
  }, []);
  return (
    <div>
      {backToTop === true ? (
          <a
            href="#"
            id="toTopBtn"
            className="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out"
            data-abc="true"
          ></a>
        ) : null}
      <Banner />
      <GetInvoled />
      <Events />
      <Reports />
      <NewsFeeds/>
      <Footer />
    </div>
  )
}

export default Home
