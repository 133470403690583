import React from "react";
import "../styles/getinvolved.css"

function GetInvoled() {
  return (
    <div className="py-5">
      <h2 style={{ textAlign: "center" }}>
        <b>GET INVOLVED</b>
      </h2>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 py-2">
          <div className="col">
            <div
              className="card  tweet-box"
             
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h5 className="card-title">
                  Support the cause and tweet using our hashtags
                </h5>
                <p>#CriminalizeTorture</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card  signup-box"
            
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h5 className="card-title">Sign our petition to</h5>
                <p>#CriminalizeTorture</p>
                <a
                  href="https://www.google.com/url?q=https://www.change.org/p/the-pakistani-government-criminalise-torture-in-pakistan?recruiter%3D1212779678%26utm_source%3Dshare_petition%26utm_medium%3Dcopylink%26utm_campaign%3Dshare_petition&sa=D&source=docs&ust=1655907947417494&usg=AOvVaw2FO23CYF0RmtwbAM4b3Me-"
                  className="btn"
                  style={{ backgroundColor: "white" }}
                >
                  Sign Up Here
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card contact-box"
             
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h5 className="card-title">Contact your MNA and ask them to</h5>
                <p>#CriminalizeTorture</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInvoled;
