import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { YouTubeEmbed } from 'react-social-media-embed';



const options = {
  // margin: 4,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  loop:true,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 1,
    },

    1000: {
      items: 1,
    },
  },
}
function YoutubeFeeds() {
  return (
    <div id="reports-on-torture">
      <div className="gallery" data-aos="fade-up">
        <div className="container py-5">
        
          <OwlCarousel {...options}>
            <div>
            <YouTubeEmbed url="https://www.youtube.com/watch?v=Gr3l4WTXGDc" width={300} height={300} />
            </div>

            <div>
            <YouTubeEmbed url="https://www.youtube.com/watch?v=KT1DzQ5tRD8" width={300} height={300} />
     
            </div>
            <div>
            <YouTubeEmbed url="https://www.youtube.com/watch?v=ZeAu4AjOoN4" width={300} height={300} />
             
            </div>

            

            
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default YoutubeFeeds
