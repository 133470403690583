import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { FacebookEmbed } from 'react-social-media-embed'

const options = {
  // margin: 4,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 1,
    },

    1000: {
      items: 1,
    },
  },
}
function FacebookFeeds() {
  return (
    <div id="reports-on-torture">
      <div className="gallery" data-aos="fade-up">
        <div className="container py-5">
          <OwlCarousel {...options}>
            <div>
              <FacebookEmbed
                url="https://www.facebook.com/JusticeProjectPakistan/videos/345127427783914"
                width={300}
                height={300}
              />
            </div>

            <div>
              <FacebookEmbed
                url="https://www.facebook.com/JusticeProjectPakistan/videos/345127427783914"
                width={300}
                height={300}
              />
            </div>
            <div>
              <FacebookEmbed
                url="https://www.facebook.com/JusticeProjectPakistan/videos/345127427783914"
                width={300}
                height={300}
              />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default FacebookFeeds
