import React from 'react'
import '../styles/footer.css'

const Footer = () => {
  return (
    <div className="mt-3" style={{ backgroundColor: 'grey', color: '#FFFFFF' }}>
      <div className="container footer-main-div">
        <p className="m-0">
          Powered by{' '}
          <a
            href="https://optiwisesolutions.com/"
           className='optiwise-solution'
          >
            Optiwise Solutions
          </a>
        </p>
        <div className="footer-icons" style={{ display: 'flex', gap: '20px' }}>
          <a href="https://twitter.com/JusticeProject_" target="_blank">
            <i className="bi bi-twitter"></i>
          </a>
          <a
            href="https://www.facebook.com/JusticeProjectPakistan"
            target="_blank"
          >
            <i className="bi bi-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/justiceprojectpak/"
            target="_blank"
          >
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
