import React from "react";
import "../styles/banner.css";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-main-div">
        {/* <div className='container'>
<div className='banner-content-div'>
<div className='banner-text'>
<h2 style={{fontSize:"48px"}}>Mock Parliament</h2>
<p>Torture is criminal. It should be a crime.</p>
</div>
<img style={{width:"300px",height:"300px"}} src={require("../assets/banner-1.png")}/>
</div>
</div> */}
      </div>

      {/* paragraph */}
      <div style={{ backgroundColor: "grey", padding: "20px 0px" }}>
        <div className="container">
          <div className="paragraph">
            <h3>
              <b>Torture is criminal. It should be a crime.</b>
            </h3>
            <p>
              Currently, there is no law to protect citizens from torture at the
              hands of the police. This means that police officials cannot be
              complained against or brought to justice, if they use torture.
            </p>
            <p>
              In July 2021, a bill criminalizing torture at the hands of law
              enforcement agencies such as the police was passed by the Senate.
              All political parties unanimously supported the bill.
            </p>
            <p>
              Please support us by asking the National Assembly to table the
              bill and pass it.
            </p>
            <p>
              JPP presents #CriminalizeTorture - a campaign aiming to raise
              awareness about the pervasive use of torture by the police and
              increase support for anti-torture legislation criminalizing
              torture in Pakistan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
