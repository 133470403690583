import React from 'react'
import "../../styles/newsfeed.css"
import InstagramFeeds from './FacebookFeeds'
import TwitterFeeds from './TwitterFeeds'
import YoutubeFeeds from './YoutubeFeeds'

const NewsFeeds = () => {
  return (
  
    <div className='container bg-image' >
    <h2 className="py-3" style={{ textAlign: 'center' }}>
           News Feeds
          </h2>
          <div className='container row row-cols-1 row-cols-md-2 row-cols-lg-3'>
<div className='col'>
<YoutubeFeeds/>
</div>
<div className='col'>
<TwitterFeeds/>
</div>
<div className='col'>
<InstagramFeeds/>
</div>
          </div>
          </div>
  )
}

export default NewsFeeds
