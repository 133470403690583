import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../styles/events.css";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },

    1000: {
      items: 3,
    },
  },
};
function Events() {
  return (
    <div id="reports-on-torture">
      <div className="gallery" data-aos="fade-up">
        <div className="container ">
          <h2 className="pb-3" style={{ textAlign: "center" }}>
            <b>EVENTS</b>
          </h2>
          <OwlCarousel {...options}>
            <div className="card overlay-box">
              <div className="black_stone_1_bg">
                <div className="desc">
                  <h3>
                    Black Stone Law School Moot Competition and LUMS Panel
                  </h3>
                  <p>
                    Justice Project Pakistan and Blackstone School of Law (BSOL)
                    jointly held the 2nd Annual Moot Court Competition at
                    Blackstone. The competition was a part of JPP’s national
                    public engagement campaign, and involved twenty teams from
                    law colleges all over Pakistan. JPP also held a panel
                    discussion at the Lahore University of Management Sciences
                    (LUMS), in collaboration with student societies, namely the
                    Historical Society, the Law and Politics Society as well as
                    Hum Aahang. The events aimed to raise awareness on the issue
                    of criminal cases involving capital punishment, torture in
                    police custody, wrongful convictions and the importance of
                    anti-torture legislation.
                  </p>
                </div>
              </div>
            </div>

            <div className="card overlay-box">
              <div className="aurat_march_bg">
                <div className="desc">
                  <h3>Aurat March 2022</h3>
                  <p>
                    The Justice Project Pakistan team marched in Aurat March
                    Lahore and Aurat March Multan demanding the criminalization
                    of torture. Everyone held up their placards and had their
                    voices heard in the crowd and through the media, especially
                    through the chants of “Zulm ki kaali raat mitao - Qanoon
                    banao! Qanoon banao!”
                  </p>
                </div>
              </div>
            </div>

            <div className="card overlay-box">
              <div className="teesri_dhun_bg">
                <div className="desc">
                  <h3>Teesri Dhun</h3>
                  <p>
                    Justice Project Pakistan, in collaboration with Olomopolo
                    Media and HOPE, staged Teesri Dhun at Surt Studio, Lahore on
                    Sunday. The all khwaja sira (transgender) cast of the play
                    offered intimate and provocative glimpses into their
                    everyday lives and struggles, belongings and longings – in
                    love, God, community, expression and existence. The play was
                    a part of JPP's #CriminalizeTorture campaign.
                  </p>
                </div>
              </div>
            </div>

            <div className="card overlay-box">
              <div className="live_with_jpp_bg">
                <div className="desc">
                  <h3>Live with JPP</h3>
                  <p>
                    JPP hosts Live with JPP every other week. The live sessions
                    are moderated by esteemed journalist Ejaz Haider and they
                    bring together experts from different fields sharing their
                    views and analysis of the endemic torture thats pervasive in
                    Pakistan. It aims to highlight the plight of those
                    vulnerable to torture and the need to criminalize torture.
                  </p>
                </div>
              </div>
            </div>

            <div className="card overlay-box">
              <div className="jpp_omct_bg">
                <div className="desc">
                  <h3>
                    JPP and OMCT webinar - Criminalizing Torture for the UNHRC
                  </h3>
                  <p>
                    World Organisation Against Torture (OMCT) and Justice
                    Project Pakistan (JPP) on Thursday hosted a webinar titled
                    “Criminalising Torture in Pakistan: The Need for an
                    Effective Legal Framework”. The webinar was held as a
                    side-event for the 49th regular session of the United
                    Nations Human Rights Council (UNHRC).
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default Events;
