import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },

    1000: {
      items: 3,
    },
  },
};
function Reports() {
  return (
    <div id="reports-on-torture">
      <div className="gallery" data-aos="fade-up">
        <div className="container py-5">
          <h2 className="pb-3" style={{ textAlign: "center" }}>
            <b>REPORTS ON TORTURE</b>
          </h2>
          <OwlCarousel {...options}>
            <div>
              <a
                href="https://www.jpp.org.pk/wp-content/uploads/2021/03/2021_03_11_Criminalising-Torture_JPP-OMCT-final.pdf"
                target="_blank"
              >
                <img
                  src={require("../assets/reports/Report_TP_15_03_21.png")}
                  width={"366px"}
                  height={"474px"}
                  alt="Report_TP_15_03_21"
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.jpp.org.pk/wp-content/uploads/2019/02/2019_01_13_PUB_Policing_as_Torture.pdf"
                target="_blank"
              >
                <img
                  src={require("../assets/reports/Policing-as-Torture.jpg")}
                  width={"366px"}
                  height={"474px"}
                  alt="Policing-as-Torture"
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.jpp.org.pk/wp-content/uploads/2018/08/policing-as-torture.pdf"
                target="_blank"
              >
                <img
                  src={require("../assets/reports/Policing_as_Torture_1.jpg")}
                  width={"366px"}
                  height={"474px"}
                  alt="Policing-as-Torture"
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.jpp.org.pk/wp-content/uploads/2018/08/abuse-of-women.pdf"
                target="_blank"
              >
                <img
                  src={require("../assets/reports/Abuse-of-Women.jpg")}
                  width={"366px"}
                  height={"474px"}
                  alt="Abuse-of-Women"
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.jpp.org.pk/wp-content/uploads/2018/08/abuse-of-juveniles.pdf"
                target="_blank"
              >
                <img
                  src={require("../assets/reports/Abuse-of-Juveniles.jpg")}
                  width={"366px"}
                  height={"474px"}
                  alt="Abuse-of-Juveniles"
                />
              </a>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default Reports;
